import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import OverviewTabs from "../../components/Overview/OverviewTabs";
import { useLocation } from "react-router-dom";
import { Product } from "../../types/product";
import { capitalizeFirstLetter } from "../../utils/utils";

const Admin: React.FC = () => {
  const { hash } = useLocation();
  const breadcrumbName = capitalizeFirstLetter(
    hash.replace("#", "").split("?")[0],
  );

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="inherit">Overview</Typography>
        <Typography color="text.primary">{breadcrumbName}</Typography>
      </Breadcrumbs>
      <OverviewTabs
        product={Product.MORTGAGE_ANALYSIS}
        type="admin"
        download={true}
        getAll={true}
      />
    </>
  );
};

export default Admin;
