import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import OrgUserList from "../../components/Organization/OrgUserList";
import { CREATE_ORG_URL, ORG_GET_API, USER_ACCEPT_INVITE, USER_DECLINE_INVITE, USER_GET_INVITES } from "../../utils/consts";
import { checkAdmin, sendRequest } from "../../utils/utils";
import { ORGANIZATION } from "../../types/organization";
import { useUser } from "../../hooks/useUser";
import { Box, Breadcrumbs, Typography, Button, Grid } from "@mui/material";
import useModal from "../../hooks/useModal";
import { OrgInvite } from "../../types/orgInvite";

const Organization: React.FC = () => {
  const [organization, setOrganization] = useState<ORGANIZATION>();
  const { orgId } = useParams<{ orgId: string }>();
  const { user } = useUser();
  const hasAdmin = checkAdmin(user);

  const {
    Modal,
    openModal,
    setMessage,
    setOkFunction,
    handleOk,
    handleOkReload,
  } = useModal();

  useEffect(() => {
    const getUserOrgUserList = async () => {
      try {
        const response = await sendRequest(ORG_GET_API, "GET", {}, true);

        if (response.status === 200 && response.data?.organization) {
          setOrganization(response.data.organization);
        } else {
          setOrganization(undefined);
        }
      } catch (error) {
        console.error("Failed to fetch organization data:", error);
        setOrganization(undefined);
      }
    };

    getUserOrgUserList();
  }, [orgId, hasAdmin]);

  const [invites, setInvites] = useState<OrgInvite[]>([]);

  useEffect(() => {
    const fetchInvites = async () => {
      try {
        const response = await sendRequest(USER_GET_INVITES, "GET", {}, true);
        if (response.status === 200 && response.data?.invites) {
          setInvites(response.data.invites);
          console.log("Invites:", response.data.invites);
        }
      } catch (error) {
        console.error("Failed to fetch invites:", error);
      }
    };

    fetchInvites();
  }, []);

  const handleAccept = async (orgInviteId: string) => {
    try {
      const response = await sendRequest(USER_ACCEPT_INVITE, "POST", { orgInviteId }, true);
      if (response.status === 200) {
        setMessage(<span className="font-bold">Invite accepted successfully.</span>);
        setOkFunction(handleOkReload);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      setMessage(
        <Box className="items-center">
          <Box className="font-bold">Failed to accept invite.</Box>
          <Box>{error instanceof Error ? error.message : "Internal Error"}</Box>
        </Box>
      );
      setOkFunction(handleOk);
    } finally {
      openModal();
    }
  };

  const handleDecline = async (orgInviteId: string) => {
    try {
      const response = await sendRequest(USER_DECLINE_INVITE, "POST", { orgInviteId }, true);
      if (response.status === 200) {
        setMessage(<span className="font-bold">Invite declined successfully.</span>);
        setOkFunction(handleOkReload);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      setMessage(
        <Box className="items-center">
          <Box className="font-bold">Failed to decline invite.</Box>
          <Box>{error instanceof Error ? error.message : "Internal Error"}</Box>
        </Box>
      );
      setOkFunction(handleOk);
    } finally {
      openModal();
    }
  };

  return (
    <>
      {Modal}
      <Box className="org-page" sx={{ padding: 2 }}>
        <Box mb={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit" className="breadcrumb">
              {organization?.name ?? "Organization"}
            </Typography>
          </Breadcrumbs>
        </Box>

        {organization ? (
          <Box mt={2}>
            <OrgUserList />
          </Box>
        ) : (
          <Box>

            <Typography variant="body1" mb={2}>
              Note: You may only be a part of one organization.
            </Typography>

            <Box display="flex" justifyContent="flex-start" mb={2}>
              <Button
                variant="contained"
                component={Link}
                to={CREATE_ORG_URL}
                color="primary"
              >
                Create Organization
              </Button>
            </Box>

            <Box>
              <Typography variant="h6" mb={2}>
                or
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" mb={2}>
                Organization Invites
              </Typography>

              {invites.length === 0 && (
                <Typography variant="body1">
                  You have no pending organization invites. Please contact your organization admin for help.
                </Typography>
              )}

              <Grid container spacing={2}>
                {invites.map((invite) => (
                  <Grid item xs={12} sm={8} md={6} key={invite._id}>
                    <Box
                      p={2}
                      border={1}
                      borderColor="grey.300"
                      borderRadius={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      minWidth="0"
                      maxWidth="100%"
                      boxSizing="border-box"
                    >
                      <Typography>{"Invited to: " + invite.organizationName}</Typography>
                      <Typography>{"Role: " + invite.role}</Typography>
                      <Typography>{"Invited by: " + invite.inviterFirstName + " " + invite.inviterLastName}</Typography>
                      <Typography>{"Admin Email: " + invite.inviterEmail}</Typography>
                      <Box mt={1} display="flex" gap={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAccept(invite._id)}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleDecline(invite._id)}
                        >
                          Decline
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Organization;
