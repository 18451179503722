import React, { useState, ReactNode, useEffect } from "react";
import Header from "../components/Header/index";
import Sidebar from "../components/Sidebar/index";
import { useUser } from "../hooks/useUser";
import { useNavigate, Outlet } from "react-router-dom";
import { MEMERS_PATH, ORG_PATH, SIGNIN_URL } from "../utils/consts";
import { Box, useMediaQuery } from "@mui/material";
import useModal from "../hooks/useModal";

const DefaultLayout = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect mobile view
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile); // Handle sidebar state
  const { Modal, setMessage, setOkFunction, handleOkRedirect, openModal } =
    useModal();

  useEffect(() => {
    if (!user) {
      navigate(SIGNIN_URL);
    }

    if (
      window.location.pathname !== `${ORG_PATH}${MEMERS_PATH}` &&
      window.location.pathname !== `${ORG_PATH}/create` &&
      !user?.organization
    ) {
      setMessage(<span>{"You need to be part of an organization first"}</span>);
      setOkFunction(() => handleOkRedirect(`${ORG_PATH}${MEMERS_PATH}`));
      openModal();
    }
  }, [user, navigate]);

  return (
    <Box className="page-container dark:bg-boxdark-2 dark:text-bodydark">
      {Modal}
      {/* ===== Page Wrapper Start ===== */}
      <Box className="flex h-screen overflow-hidden">
        {/* ===== Sidebar Start ===== */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* ===== Sidebar End ===== */}

        {/* ===== Content Area Start ===== */}
        <Box
          className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
          sx={{
            display: isMobile && sidebarOpen ? "none" : "flex", // Hide content on mobile when sidebar is open
          }}
        >
          {/* ===== Header Start ===== */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* ===== Header End ===== */}

          {/* ===== Main Content Start ===== */}
          <main>
            <Box className="mx-auto px-4 pt-6 md:px-6 2xl:px-10">
              <Outlet />
            </Box>
          </main>
          {/* ===== Main Content End ===== */}
        </Box>
        {/* ===== Content Area End ===== */}
      </Box>
      {/* ===== Page Wrapper End ===== */}
    </Box>
  );
};

export default DefaultLayout;
