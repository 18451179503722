import React, { useState, ChangeEvent, FocusEvent } from "react";
import useModal from "../../hooks/useModal";
import { sendRequest } from "../../utils/utils";
import { GENERATE_CODE_API } from "../../utils/consts";
import { StatusType } from "../../types/statusType";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import UserRole from "../../types/userRole";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Button,
  SelectChangeEvent,
} from "@mui/material";

const AdminManage: React.FC = () => {
  const [role, setRole] = useState<string>(UserRole.USER);
  const [validationStatus, setValidationStatus] =
    useState<StatusType>("default");
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [invitationCode, setInvitationCode] = useState<string>("");
  const [copySuccess, setCopySuccess] = useState<string>("");
  const { Modal, openModal, setMessage, setOkFunction, handleOk } = useModal();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setRole(value);
    validateRole(value);
  };

  const handleBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    validateRole(role);
  };

  const validateRole = (value: string) => {
    if (!value) {
      setValidationStatus("error");
      setValidationMessage("Role is required");
    } else {
      setValidationStatus("default");
      setValidationMessage("");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(invitationCode).then(
      () => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000);
      },
      () => {
        setCopySuccess("Failed to copy!");
      },
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!role) {
      setValidationStatus("error");
      setValidationMessage("Role is required");
      return;
    }

    try {
      const response = await sendRequest(
        GENERATE_CODE_API,
        "POST",
        { role },
        true,
      );
      if (response.status === 200) {
        setMessage(<span>Invitation Code Generation Successfully</span>);
        setInvitationCode(response.data.code);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      setMessage(
        <span>
          Invitation Code Generation Failed:{" "}
          {error instanceof Error ? error.message : "Internal Error"}
        </span>,
      );
      openModal();
    } finally {
      setOkFunction(handleOk);
    }
  };

  return (
    <>
      {Modal}
      <Box className="card !rounded-lg text-black dark:text-white mb-5 lg:w-1/2 p-7">
        <form onSubmit={handleSubmit} className="w-full max-w-md">
          {invitationCode && (
            <Box className="text-lg mb-5">
              <Box className="flex gap-1">
                <Box>Invitation Code: {invitationCode}</Box>
                <DocumentDuplicateIcon
                  onClick={copyToClipboard}
                  className="pt-1 icon-sm cursor-pointer"
                />
              </Box>
              {copySuccess && (
                <Box className="text-green-500">{copySuccess}</Box>
              )}
              <Box>This is a one-time use code and will expire in 1 day</Box>
            </Box>
          )}
          <FormControl
            fullWidth
            error={validationStatus === "error"}
            className="!mb-4"
          >
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              name="role"
              value={role}
              onChange={handleChange}
              onBlur={handleBlur} // Corrected to use handleBlur
              label="Role"
            >
              <MenuItem value={UserRole.SUPERADMIN}>
                {UserRole.SUPERADMIN}
              </MenuItem>
              <MenuItem value={UserRole.USER}>{UserRole.USER}</MenuItem>
            </Select>
            {validationMessage && (
              <FormHelperText>{validationMessage}</FormHelperText>
            )}
          </FormControl>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Generate Invitation Code
          </Button>
        </form>
      </Box>
    </>
  );
};

export default AdminManage;
