import React from "react";
import { useLocation } from "react-router-dom";
import { Product } from "../../types/product";
import { Breadcrumbs, Typography } from "@mui/material";
import OverviewTabs from "../../components/Overview/OverviewTabs";
import { capitalizeFirstLetter } from "../../utils/utils";

const Overview: React.FC<{ product: Product; isArchive?: boolean }> = ({
  product,
  isArchive = false,
}) => {
  const { hash } = useLocation();
  const breadcrumbName = capitalizeFirstLetter(
    hash.replace("#", "").split("?")[0],
  );

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="inherit" className="breadcrumb">
          {breadcrumbName} {isArchive ? "Archive" : "Overview"}
        </Typography>
      </Breadcrumbs>
      <OverviewTabs product={product} isArchive={isArchive} download={false} />
    </>
  );
};

export default Overview;
