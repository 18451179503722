import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useRef,
  useEffect,
} from "react";
import Snackbar from "@mui/material/Snackbar";
import WebsocketService from "../components/Websockets/WebsocketService";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { SlideProps } from "@mui/material";
import { Link } from "react-router-dom";

interface FileProgress {
  error: boolean;
  fileId: string;
  percent: number;
  fileName: string;
}

interface ApplicantState {
  applicantId: string;
  fileProgress: FileProgress[];
}

interface SnackbarState {
  applicants: ApplicantState[];
  message: string;
  severity: AlertColor;
}

interface SnackbarContextProps {
  addApplicant: (applicantId: string) => void;
  removeApplicant: (applicantId: string) => void;
  updateFileProgress: (
    applicantId: string,
    fileId,
    percent: number,
    fileName: string,
    isError: boolean,
  ) => void;
  handleCloseSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined,
);

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    applicants: [],
    message: "",
    severity: "info",
  });

  const websocketServicesRef = useRef<Map<string, WebsocketService>>(new Map());

  const addApplicant = useCallback((applicantId: string) => {
    setSnackbarState((prev) => ({
      ...prev,
      applicants: [...prev.applicants, { applicantId, fileProgress: [] }],
    }));

    const websocketService = new WebsocketService();
    websocketService.connectFileSocket(applicantId, (fileId, status) => {
      updateFileProgress(
        applicantId,
        fileId.fileId,
        fileId.status.percent,
        fileId.fileName,
        fileId.status.error,
      );
    });
    websocketServicesRef.current.set(applicantId, websocketService);
  }, []);

  const removeApplicant = useCallback((applicantId: string) => {
    setSnackbarState((prev) => ({
      ...prev,
      applicants: prev.applicants.filter(
        (app) => app.applicantId !== applicantId,
      ),
    }));

    const websocketService = websocketServicesRef.current.get(applicantId);
    if (websocketService) {
      websocketService.closeFileSocket();
      websocketServicesRef.current.delete(applicantId);
    }
  }, []);

  const updateFileProgress = useCallback(
    (
      applicantId: string,
      fileId,
      percent: number,
      fileName,
      isError: boolean,
    ) => {
      setSnackbarState((prev) => {
        const updatedApplicants = prev.applicants.map((applicant) => {
          if (applicant.applicantId === applicantId) {
            const updatedFileProgress = applicant.fileProgress.some(
              (file) => file.fileId === fileId,
            )
              ? applicant.fileProgress.map((file) =>
                  file.fileId === fileId
                    ? { ...file, percent, fileName, error: isError }
                    : file,
                )
              : [
                  ...applicant.fileProgress,
                  { fileId, percent, fileName, error: isError },
                ];
            return { ...applicant, fileProgress: updatedFileProgress };
          }
          return applicant;
        });

        /*
        const applicant = updatedApplicants.find(
          (app) => app.applicantId === applicantId,
        );
        if (
          applicant &&
          applicant.fileProgress.every((file) => file.percent === 100)
        ) {
          //removeApplicant(applicantId);
        }
          */

        return { ...prev, applicants: updatedApplicants };
      });
    },
    [removeApplicant],
  );

  const handleCloseSnackbar = useCallback(() => {
    websocketServicesRef.current.forEach((websocketService) => {
      websocketService.closeFileSocket();
    });
    websocketServicesRef.current.clear();

    setSnackbarState({
      applicants: [],
      message: "",
      severity: "info",
    });

    wasSnackbarOpened.current = false;
  }, []);

  const slideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="left" />;
  };

  const shouldShowSnackbar = snackbarState.applicants.some(
    (applicant) => applicant.fileProgress.length > 0,
  );

  useEffect(() => {
    return () => {
      handleCloseSnackbar();
    };
  }, [handleCloseSnackbar]);

  const wasSnackbarOpened = useRef(false);

  useEffect(() => {
    // Close the snackbar automatically if all files are processed
    const allFilesProcessed = snackbarState.applicants.every((applicant) =>
      applicant.fileProgress.every((file) => file.percent === 100),
    );
    const isError = snackbarState.applicants.some((applicant) =>
      applicant.fileProgress.some((file) => file.error),
    );
    if (
      wasSnackbarOpened.current &&
      allFilesProcessed &&
      snackbarState.applicants.length > 0 &&
      !isError
    ) {
      setTimeout(() => {
        handleCloseSnackbar();
      }, 3000);
    }
  }, [snackbarState.applicants, handleCloseSnackbar]);

  return (
    <SnackbarContext.Provider
      value={{
        addApplicant,
        removeApplicant,
        updateFileProgress,
        handleCloseSnackbar,
      }}
    >
      {children}
      {snackbarState.applicants.length > 0 && (
        <div>
          {(() => {
            const totalFiles = snackbarState.applicants.reduce(
              (acc, applicant) => acc + applicant.fileProgress.length,
              0,
            );
            const completedFiles = snackbarState.applicants.reduce(
              (acc, applicant) =>
                acc +
                applicant.fileProgress.filter((file) => file.percent === 100)
                  .length,
              0,
            );

            // If any of the files have error = true then show error snackbar
            const isError = snackbarState.applicants.some((applicant) =>
              applicant.fileProgress.some((file) => file.error),
            );

            if (isError) {
              return (
                <Snackbar
                  className="snack-bar"
                  open={true}
                  autoHideDuration={null}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  sx={{
                    backgroundColor: "rgba(248, 152, 128, 0.8)",
                    marginTop: "50px",
                    marginRight: "0px",
                    color: "white",
                    padding: "10px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  TransitionComponent={slideTransition}
                  onClick={handleCloseSnackbar}
                >
                  <Link to="/mortgageAnalysis/overview#applicants">
                    <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                      PII Removal Failed: {completedFiles}/{totalFiles}
                    </div>
                  </Link>
                </Snackbar>
              );
            }

            if (totalFiles > 0) {
              wasSnackbarOpened.current = true;
              return (
                <Snackbar
                  open={snackbarState.applicants.length > 0}
                  autoHideDuration={null}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  sx={{
                    backgroundColor: "rgba(248, 152, 128, 0.8)",
                    marginTop: "50px",
                    marginRight: "0px",
                    color: "white",
                    padding: "10px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={handleCloseSnackbar}
                >
                  <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                    PII Removal Completion: {completedFiles}/{totalFiles}
                  </div>
                </Snackbar>
              );
            }
            return null;
          })()}
        </div>
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextProps => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};
