import React from "react";
import ApplicationInfoForm from "../../components/Application/ApplicationInfoForm";
import { Product } from "../../types/product";
import { Breadcrumbs, Typography } from "@mui/material";

const AddApplication: React.FC<{ product: Product; edit?: boolean }> = ({
  product,
  edit = false,
}) => {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" className="breadcrumb">
          {edit ? "Modify" : "Create"} File
        </Typography>
      </Breadcrumbs>
      <ApplicationInfoForm product={product} edit={edit} />
    </>
  );
};

export default AddApplication;
