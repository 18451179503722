import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Email as EnvelopeIcon,
  Lock as LockClosedIcon,
  Person as UserIcon,
} from "@mui/icons-material";
import About from "../../components/Authentication/About";
import { getResponseClassName, sendRequest } from "../../utils/utils";
import { StatusType } from "../../types/statusType";
import { SIGNIN_URL, SIGNUP_API } from "../../utils/consts";
import useModal from "../../hooks/useModal";
import UserRole from "../../types/userRole";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SignUp: React.FC = () => {
  const navigate = useNavigate();

  const [responseStatus, setResponseStatus] = useState<StatusType>("default");
  const [responseMessage, setResponseMessage] = useState("");
  const { Modal, openModal, setMessage, setOkFunction, handleOkRedirect } =
    useModal();

  const [credentials, setCredentials] = useState({
    code: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rePassword: "",
  });

  const [validationStatus, setValidationStatus] = useState({
    codeStatus: "default" as StatusType,
    firstNameStatus: "default" as StatusType,
    lastNameStatus: "default" as StatusType,
    emailStatus: "default" as StatusType,
    passwordStatus: "default" as StatusType,
    rePasswordStatus: "default" as StatusType,
  });

  const [validationMessage, setValidationMessage] = useState({
    codeMessage: "",
    firstNameMessage: "",
    lastNameMessage: "",
    emailMessage: "",
    passwordMessage: "",
    rePasswordMessage: "",
  });

  const validateInput = (name: string, value: string) => {
    switch (name) {
      case "code":
        return value ? "" : "Invitation Code is required";
      case "firstName":
        return value ? "" : "First Name is required";
      case "lastName":
        return value ? "" : "Last Name is required";
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return value
          ? emailRegex.test(value)
            ? ""
            : "Invalid email format"
          : "Email is required";
      case "password":
        const passwordErrors = [];
        if (!value) {
          passwordErrors.push("Password is required");
        } else {
          if (value.length < 8) {
            passwordErrors.push("Password must be at least 8 characters");
          }
          if (!/[A-Z]/.test(value)) {
            passwordErrors.push(
              "Password must contain at least one uppercase letter",
            );
          }
          if (!/[a-z]/.test(value)) {
            passwordErrors.push(
              "Password must contain at least one lowercase letter",
            );
          }
          if (!/[0-9]/.test(value)) {
            passwordErrors.push("Password must contain at least one number");
          }
          if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
            passwordErrors.push(
              "Password must contain at least one special character",
            );
          }
        }
        return passwordErrors.join(", ");
      case "rePassword":
        return value === credentials.password ? "" : "Passwords do not match";
      default:
        return "";
    }
  };

  const validateAndSetField = (name: string, value: string) => {
    const errorMessage = validateInput(name, value);
    const status = errorMessage ? "error" : "default";

    setCredentials((prev) => ({ ...prev, [name]: value }));
    setValidationStatus((prev) => ({ ...prev, [`${name}Status`]: status }));
    setValidationMessage((prev) => ({
      ...prev,
      [`${name}Message`]: errorMessage,
    }));

    return !errorMessage;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    validateAndSetField(name, value);
  };

  const validateForm = () => {
    const fieldsToValidate = [
      { name: "code", value: credentials.code },
      { name: "firstName", value: credentials.firstName },
      { name: "lastName", value: credentials.lastName },
      { name: "email", value: credentials.email },
      { name: "password", value: credentials.password },
      { name: "rePassword", value: credentials.rePassword },
    ];

    let isValid = true;

    fieldsToValidate.forEach((field) => {
      if (!validateAndSetField(field.name, field.value)) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const data = { ...credentials, role: UserRole.USER };
      const response = await sendRequest(SIGNUP_API, "POST", data);
      if (response.status === 200) {
        setMessage(
          <span className="font-bold">{"You signed up successfully!"}</span>,
        );
        setOkFunction(() => handleOkRedirect("/auth/signin"));
        openModal();
      } else {
        setResponseStatus("error");
        setResponseMessage(response.message);
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage("Internal Server Error");
    }
  };

  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showRePassword, setShowRePassword] = useState(false); // State to toggle password visibility

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle between true and false
  };
  const handleToggleRePasswordVisibility = () => {
    setShowRePassword(!showRePassword); // Toggle between true and false
  };

  return (
    <>
      {Modal}
      <Box className="flex justify-center">
        <Typography
          color="dark"
          sx={{
            fontSize: 24,
            fontWeight: 800,
            color: (theme) => theme.palette.secondary.dark,
          }}
        >
          See what you can do with FinQuantum Inc.
        </Typography>
      </Box>

      <Box className="flex flex-wrap">
        <About />

        <Box className="form-container xl:w-1/2 !w-[700px]">
          <form onSubmit={handleSubmit}>
            <TextField
              label="Invitation Code"
              name="code"
              value={credentials.code}
              error={validationStatus.codeStatus === "error"}
              helperText={validationMessage.codeMessage}
              onChange={handleChange}
              onBlur={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <UserIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="First Name"
              name="firstName"
              value={credentials.firstName}
              error={validationStatus.firstNameStatus === "error"}
              helperText={validationMessage.firstNameMessage}
              onChange={handleChange}
              onBlur={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <UserIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Last Name"
              name="lastName"
              value={credentials.lastName}
              error={validationStatus.lastNameStatus === "error"}
              helperText={validationMessage.lastNameMessage}
              onChange={handleChange}
              onBlur={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <UserIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Email"
              type="email"
              name="email"
              value={credentials.email}
              error={validationStatus.emailStatus === "error"}
              helperText={validationMessage.emailMessage}
              onChange={handleChange}
              onBlur={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EnvelopeIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}  // Change type based on state
              name="password"
              value={credentials.password}
              error={validationStatus.passwordStatus === "error"}
              helperText={validationMessage.passwordMessage}
              onChange={handleChange}
              onBlur={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockClosedIcon />
                  </InputAdornment>
                ),
                // Add an adornment to the end of the input field for the toggle button
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Re-enter Password"
              type={showRePassword ? "text" : "password"}  // Change type based on state
              name="rePassword"
              value={credentials.rePassword}
              error={validationStatus.rePasswordStatus === "error"}
              helperText={validationMessage.rePasswordMessage}
              onChange={handleChange}
              onBlur={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockClosedIcon />
                  </InputAdornment>
                ),
                // Add an adornment to the end of the input field for the toggle button
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle re-password visibility"
                      onClick={handleToggleRePasswordVisibility}
                      edge="end"
                    >
                      {showRePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box className={getResponseClassName(responseStatus)}>
              {responseMessage}
            </Box>

            <Box className="mt-15 mb-5 flex flex-col gap-6">
              <Box className="flex justify-center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Sign Up
                </Button>
              </Box>
              <Box className="mt-6 text-center">
                <Typography>
                  Already have an account?{" "}
                  <Link to={SIGNIN_URL} className="text-primary">
                    Sign in
                  </Link>
                </Typography>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default SignUp;
