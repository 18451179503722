import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Loader from "./common/Loader";
import PageTitle from "./components/PageTitle";
import SignIn from "./pages/Authentication/SignIn";
import SignUp from "./pages/Authentication/SignUp";
import IncomeAnalysis from "./pages/Products/IncomeAnalysis";
import AddOrganization from "./pages/Organization/AddOrganization";
import Organization from "./pages/Organization/Organization";
import AddApplication from "./pages/Products/AddApplication";
import MortgageAnalysis from "./pages/Products/MortgageAnalysis";
import { Product } from "./types/product";
import {
  ADD_APPLICATION_PATH,
  ADD_REPORT_PATH,
  ADMIN_PATH,
  APPLICATION_STATUS_UPDATE_PATH,
  ARCHIVE_PATH,
  DOWNLOAD_REPORT_PATH,
  EDIT_APPLICATION_PATH,
  MANAGE_PATH,
  MEMERS_PATH,
  MORTGAGE_ANALYSIS_URL,
  ORG_PATH,
  OVERVIEW_PATH,
  SERVICE_PATH,
  SIGNIN_URL,
} from "./utils/consts";
import AddReport from "./pages/Report/AddReport";
import Admin from "./pages/Admin/Admin";
import { useUser } from "./hooks/useUser";
import { getUser } from "./utils/utils";
import AdminManage from "./pages/Admin/AdminManage";
import UserRole from "./types/userRole";
import Overview from "./pages/Overview/Overview";
import DownloadReport from "./pages/Report/DownloadReport";
import ApplicationStatusUpdate from "./pages/Products/ApplicationStatusUpdate";
import OrgManage from "./pages/Organization/OrgManage";
import DefaultLayout from "./layout/DefaultLayout";
import SsoLayout from "./layout/SsoLayout";

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const { pathname } = useLocation();
  var { user, setUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const handleGetUser = async () => {
      const user = await getUser();
      if (user) {
        setUser(user);
        setIsAdmin(user?.role === UserRole.SUPERADMIN);
      } else {
        navigate(SIGNIN_URL);
      }
    };
    if (!user) {
      handleGetUser();
    } else {
      setIsAdmin(user?.role === UserRole.SUPERADMIN);
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route
            index
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <MortgageAnalysis />
              </>
            }
          />
          <Route
            path="/incomeAnalysis"
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <IncomeAnalysis />
              </>
            }
          />
          <Route
            path={MORTGAGE_ANALYSIS_URL}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <MortgageAnalysis />
              </>
            }
          />
          <Route
            path={`${MORTGAGE_ANALYSIS_URL}${ADD_APPLICATION_PATH}`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <AddApplication product={Product.MORTGAGE_ANALYSIS} />
              </>
            }
          />
          <Route
            path={`${MORTGAGE_ANALYSIS_URL}${EDIT_APPLICATION_PATH}`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <AddApplication product={Product.MORTGAGE_ANALYSIS} edit />
              </>
            }
          />
          <Route
            path={`${MORTGAGE_ANALYSIS_URL}${APPLICATION_STATUS_UPDATE_PATH}`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <ApplicationStatusUpdate />
              </>
            }
          />
          <Route
            path={`${MORTGAGE_ANALYSIS_URL}${ADD_REPORT_PATH}`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <AddReport product={Product.MORTGAGE_ANALYSIS} />
              </>
            }
          />
          <Route
            path={`${MORTGAGE_ANALYSIS_URL}${ADD_REPORT_PATH}:applicationId`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <AddReport product={Product.MORTGAGE_ANALYSIS} />
              </>
            }
          />
          <Route
            path={`${MORTGAGE_ANALYSIS_URL}${DOWNLOAD_REPORT_PATH}`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <DownloadReport product={Product.MORTGAGE_ANALYSIS} />
              </>
            }
          />
          <Route
            path={`${MORTGAGE_ANALYSIS_URL}${OVERVIEW_PATH}`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <Overview product={Product.MORTGAGE_ANALYSIS} />
              </>
            }
          />
          <Route
            path={`${MORTGAGE_ANALYSIS_URL}${ARCHIVE_PATH}`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <Overview product={Product.MORTGAGE_ANALYSIS} isArchive />
              </>
            }
          />
          <Route
            path={`${ORG_PATH}${MEMERS_PATH}`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <Organization />
              </>
            }
          />
          <Route
            path={`${ORG_PATH}${MANAGE_PATH}`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <OrgManage />
              </>
            }
          />
          <Route
            path={`${ORG_PATH}/:orgId`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <Organization />
              </>
            }
          />
          <Route
            path={`${ORG_PATH}/create`}
            element={
              <>
                <PageTitle title="FinQuantum Inc." />
                <AddOrganization />
              </>
            }
          />
          {isAdmin && (
            <>
              <Route
                path={`${ADMIN_PATH}${SERVICE_PATH}`}
                element={
                  <>
                    <PageTitle title="FinQuantum Inc. Admin" />
                    <Admin />
                  </>
                }
              />
              <Route
                path={`${ADMIN_PATH}${MANAGE_PATH}`}
                element={
                  <>
                    <PageTitle title="FinQuantum Inc. Admin" />
                    <AdminManage />
                  </>
                }
              />
              <Route
                path={`${ADMIN_PATH}${ORG_PATH}/:orgId`}
                element={
                  <>
                    <PageTitle title="FinQuantum Inc. Admin" />
                    <MortgageAnalysis />
                  </>
                }
              />
            </>
          )}
        </Route>
        <Route element={<SsoLayout />}>
          <Route
            path="/auth/signin"
            element={
              <>
                <PageTitle title="Sign In | FinQuantum Inc." />
                <SignIn />
              </>
            }
          />
          <Route
            path="/auth/signup"
            element={
              <>
                <PageTitle title="Sign Up | FinQuantum Inc." />
                <SignUp />
              </>
            }
          />
          {/*Catch all - Redirect to the index */}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
