import React from "react";
import { Link } from "react-router-dom";
import OrgInfoForm from "../../components/Organization/OrgInfoForm";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";

const AddOrganization: React.FC = () => {
  return (
    <>
      <Box className="mt-4 md:mt-6 2xl:mt-7.5">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit" className="breadcrumb">
            Create Organization
          </Typography>
        </Breadcrumbs>
      </Box>
      <OrgInfoForm />
    </>
  );
};

export default AddOrganization;
