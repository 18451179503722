import React, { useEffect, useState } from "react";
import { ORG_GET_API } from "../../utils/consts";
import { sendRequest } from "../../utils/utils";
import { useUser } from "../../hooks/useUser";
import useModal from "../../hooks/useModal";
import ApplicationStatisticsTable from "../../components/Application/ApplicationStatisticsTable";
import ApplicationHomeTable from "../../components/Application/ApplicationHomeTable";
import { Box, Typography } from "@mui/material";

const MortgageAnalysis: React.FC = () => {
  const { user } = useUser();

  const [orgName, setOrgName] = useState("N/A");

  useEffect(() => {
    const getOrgName = async () => {
      const response = await sendRequest(
        ORG_GET_API,
        "GET",
        {
          orgId: user?.organization?.id,
        },
        true,
        true,
      );
      if (response.status === 200 && response.data) {
        try {
          setOrgName(response.data.organization.name);
        } catch (e) {
          console.log("Couldnt fetch org data", e);
        }
      }
    };
    getOrgName();
  }, [user]);

  return (
    <>
      {user?.organization && (
        <Box
          className="homepage"
          style={{
            height: "calc(100vh - 253x)",
            overflowY: "auto",
          }}
        >
          <h1 className="text-2xl font-semibold text-black dark:text-white my-4">
            Hello, {user.firstName} {user.lastName}
          </h1>
          <Typography className="text-sm text-gray-500 dark:text-gray-400">
            <strong>Organization: </strong>
            {orgName}
          </Typography>
          <Box className="mt-4 md:mt-6 2xl:mt-7.5">
            <ApplicationStatisticsTable></ApplicationStatisticsTable>
          </Box>
          <Box className="mt-4 md:mt-6 2xl:mt-7.5">
            <ApplicationHomeTable></ApplicationHomeTable>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MortgageAnalysis;
