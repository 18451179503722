import React from "react";
import { Product } from "../../types/product";
import ReportList from "../../components/Report/ReportList";
import { Box, Breadcrumbs, Typography } from "@mui/material";

const DownloadReport: React.FC<{ product: Product }> = ({ product }) => {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" className="breadcrumb">
          Download Report
        </Typography>
      </Breadcrumbs>
      <Box className="download-page p-5">
        <Box className="info-card">
          In the trial period, reports could take up to 48 hours to complete
          before it can be downloaded. When the status says “report complete,”
          it is ready to be downloaded. Please check back in 48 hours.
        </Box>
        <ReportList product={product} type="download" download={true} />
      </Box>
    </>
  );
};

export default DownloadReport;
