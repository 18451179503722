import React from "react";
import { Box, Breadcrumbs, Button, Grid, Typography } from "@mui/material";
import ApplicationStatusEdit from "../../components/Application/ApplicationStatusEdit";

const ApplicationStatusUpdate: React.FC = () => {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" className="breadcrumb">
          Update File Status
        </Typography>
      </Breadcrumbs>
      <Box className="file-status-page mt-4 md:mt-6 2xl:mt-7.5">
        <ApplicationStatusEdit />
      </Box>
    </>
  );
};

export default ApplicationStatusUpdate;
