import React, { ReactNode, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { Box } from "@mui/material";
import SsoHeader from "../components/Header/SsoHeader";

const SsoLayout = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  });

  return (
    <Box className="page-container dark:bg-boxdark-2 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <SsoHeader />
      <Box className="flex justify-center">
        {/* <!-- ===== Main Content Start ===== --> */}
        <main>
          <Box className="mx-auto p-4 md:p-6 2xl:p-10">
            <Outlet />
          </Box>
        </main>
      </Box>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </Box>
  );
};

export default SsoLayout;
