import React from "react";
import { Link } from "react-router-dom";
import { ADD_APPLICATION_PATH } from "../../utils/consts";
import { Product } from "../../types/product";
import ApplicationList from "../../components/Application/ApplicationList";
import { getProductUrl } from "../../utils/utils";
import { Box } from "@mui/material";

const IncomeAnalysis: React.FC = () => {
  return (
    <>
      <Box className="flex w-full">
        <Link
          className="button"
          to={`${getProductUrl(
            Product.INCOME_ANALYSIS,
          )}${ADD_APPLICATION_PATH}`}
        >
          + Add New Application
        </Link>
      </Box>
      <Box className="mt-4 md:mt-6 2xl:mt-7.5">
        <ApplicationList product={Product.INCOME_ANALYSIS} />
      </Box>
    </>
  );
};

export default IncomeAnalysis;
