import { PaletteMode, rgbToHex, ThemeOptions, withTheme } from "@mui/material";
import { blue, deepOrange, grey } from "@mui/material/colors";

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  typography: {
    fontFamily: '"Nunito Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightRegular: 600,
    fontSize: 14,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "0",
          backgroundColor: "#FFF",
          "--DataGrid-headerHeight": 800,
        },
        scrollbars: false,
        "& .MuiDataGrid-row": {
          border: 1,
        },
        "& .MuiDataGrid-cell": {
          border: 0,
        },
        "& .MuiDataGrid-columnHeaders": {
          fontSize: 16,
          fontWeight: 800,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: "400px",
          minHeight: "200px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          padding: "8px 12px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: "40px",
          minWidth: "60px",
          padding: "12px 20px",
          color: "#fff",
          textTransform: "none",
        },
        outlinedSecondary: {
          backgroundColor: "rgba(0, 0, 0, 0)",
          color: "rgb(0, 0, 0)",
          border: "2px solid rgb(0, 0, 0)",
          ":hover": {
            backgroundColor: "rgba(240, 240, 240, 0.9)",
            border: "2px solid rgb(0, 0, 0)",
          },
        },
        containedSecondary: {
          backgroundColor: "rgb(135,206,250)",
          color: "rgb(0, 0, 0)",
          ":hover": {
            backgroundColor: "rgba(135,206,250, 0.8)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: ">", // Set your global separator here
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          marginInline: 10,
          "&.Mui-selected": {
            backgroundColor:
              mode === "light" ? "rgb(62, 92, 250)" : deepOrange[500], // Use primary.main based on the mode
            color: "rgb(250, 250, 250)",
            "&:hover": {
              backgroundColor:
                mode === "light" ? "rgb(62, 100, 250)" : deepOrange[700], // Darker shade on hover
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: 35,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          width: 20,
        },
      },
    },
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "rgb(62, 92, 250)",
            light: "rgba(60, 80, 224, 0.9)",
            dark: "rgba(60, 80, 224, 0.9)",
            contrastText: "rgb(32, 34, 36)",
          },
          secondary: {
            main: "rgba(0, 0, 0, 0)",
            dark: "rgb(102, 102, 102)",
          },
          Boxider: blue[200],
          navbar: {
            background: "#FFF",
          },
        }
      : {
          // palette values for dark mode
          primary: deepOrange,
          Boxider: deepOrange[700],
          background: {
            default: deepOrange[900],
            paper: deepOrange[900],
          },
          text: {
            primary: "#fff",
            secondary: grey[500],
          },
        }),
  },
});

export default getDesignTokens;
