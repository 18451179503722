import React, { useState, useEffect } from "react";
import useModal from "../../hooks/useModal";
import { checkOrgRole, fetchDataList, sendRequest } from "../../utils/utils";
import {
  ORG_DELETE_INVITE,
  ORG_GET_API,
  ORG_GET_INVITES,
  ORG_GET_STATUS_LIST_API,
  ORG_INVITE_USER,
  ORG_UPDATE_CUSTOM_STATUS_API,
} from "../../utils/consts";
import { StatusType } from "../../types/statusType";
import {
  Box,
  FormControl,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Breadcrumbs,
  Typography,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { OrgInvite } from "../../types/orgInvite";


const OrgManage: React.FC = () => {
  const [validationStatus, setValidationStatus] =
    useState<StatusType>("default");
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [statusList, setStatusList] = useState<string[]>([]);
  const [uniqueStatusList, setUniqueStatusList] = useState<string[]>([]);
  const [newStatus, setNewStatus] = useState<string>("");
  const [showStatusInput, setShowStatusInput] = useState<boolean>(false); // New state for showing input field

  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [inviteRole, setInviteRole] = useState<string>("Member");
  const [orgInvites, setOrgInvites] = useState<OrgInvite[] | undefined>([]);
  const [showInviteInput, setShowInviteInput] = useState<boolean>(false);

  const {
    Modal,
    openModal,
    setMessage,
    setOkFunction,
    handleOk,
    handleOkReload,
  } = useModal();
  const location = useLocation();
  const { user } = useUser();
  const isOrgAdmin = checkOrgRole(user);

  useEffect(() => {
    const getStatusList = async () => {
      try {
        const data = await fetchDataList(ORG_GET_STATUS_LIST_API, {});
        setStatusList(data.statusList);
        setUniqueStatusList(
          data.uniqueStatusList.map((status: string) => status.toLowerCase()),
        );
      } catch (error) {
        console.error("Failed to fetch status list:", error);
      }
    };
    getStatusList();
  }, [location]);

  const handleAddStatusClick = () => {
    setShowStatusInput(true);
  };

  const handleAddStatus = () => {
    if (newStatus.trim() === "") {
      setValidationStatus("error");
      setValidationMessage("Status name cannot be empty");
      return;
    }
    if (
      statusList
        .map((status: string) => status.toLowerCase())
        .includes(newStatus)
    ) {
      setMessage("Duplicate status name");
      setOkFunction(handleOk);
      openModal();
    } else {
      setStatusList((prevStatusList) => [...prevStatusList, newStatus]);
      setNewStatus("");
      setShowStatusInput(false); // Hide input after adding
    }
  };

  const handleRemoveStatus = (index: number) => {
    if (uniqueStatusList.includes(statusList[index].toLowerCase())) {
      setMessage("Can not delete status that is in use");
      setOkFunction(handleOk);
      openModal();
    } else {
      const updatedStatusList = statusList.filter((_, i) => i !== index);
      setStatusList(updatedStatusList);
    }
  };

  const handleSaveStatus = async () => {
    try {
      const response = await sendRequest(
        ORG_UPDATE_CUSTOM_STATUS_API,
        "POST",
        {
          mortgage_status_custom: statusList,
        },
        true,
      );
      if (response.status === 200) {
        setMessage(<span className="font-bold">Status saved</span>);
        setOkFunction(handleOkReload);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      setMessage(
        <Box className="items-center">
          <Box className="font-bold">Status update failed.</Box>
          <Box>{error instanceof Error ? error.message : "Internal Error"}</Box>
        </Box>,
      );
      setOkFunction(handleOk);
    } finally {
      openModal();
    }
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewStatus(e.target.value);
    if (e.target.value.trim() !== "") {
      setValidationStatus("default");
      setValidationMessage("");
    }
  };

  // Inviting members
  useEffect(() => {
    const getOrgInvites = async () => {
      try {
        const response = await sendRequest(ORG_GET_INVITES + "?all=true", "GET", {}, true);
        if (response.status === 200 && response.data?.invites) {
          setOrgInvites(response.data.invites);
        } else {
          setOrgInvites([]);
        }
      } catch (error) {
        console.error("Failed to fetch organization data:", error);
        setOrgInvites([]);
      }
    };
    getOrgInvites();
    console.log("orgInvites");
    console.log(orgInvites);
  }, []);

  const handleInviteSave = async () => {
    try {
      const response = await sendRequest(ORG_INVITE_USER, "POST", {
        role: inviteRole,
        email: inviteEmail,
      }, true);
      if (response.status === 200) {
        setMessage(<span className="font-bold">Invite sent</span>);
        setOkFunction(handleOkReload);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      setMessage(
        <Box className="items-center">
          <Box className="font-bold">Invite failed.</Box>
          <Box>{error instanceof Error ? error.message : "Internal Error"}</Box>
        </Box>,
      );
      setOkFunction(handleOk);
    } finally {
      openModal();
    }
  };

  const handleDeleteInvite = async (inviteId: string) => {
    try {
      const response = await sendRequest(ORG_DELETE_INVITE, "POST", {
        inviteId,
      }, true);
      if (response.status === 200) {
        setMessage(<span className="font-bold">Invite deleted</span>);
        setOkFunction(handleOkReload);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      setMessage(
        <Box className="items-center">
          <Box className="font-bold">Delete failed.</Box>
          <Box>{error instanceof Error ? error.message : "Internal Error"}</Box>
        </Box>,
      );
      setOkFunction(handleOk);
    } finally {
      openModal();
    }
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography className="breadcrumb">Manage Organization</Typography>
      </Breadcrumbs>
      {Modal}
      {isOrgAdmin ? (
        // Make these two boxes go side by side
        <Box className="flex gap-5">

          <Box className="card !rounded-lg text-black dark:text-white my-5 lg:w-1/2 pt-5 px-7 pb-7">
            <Typography variant="h5" className="text-xl font-bold mb-4">
              File Status
            </Typography>
            <List>
              {statusList.map((status, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveStatus(index)}
                    >
                      <Close />
                    </IconButton>
                  }
                >
                  <ListItemText primary={status} />
                </ListItem>
              ))}
            </List>

            {showStatusInput ? (
              <Box className="flex mb-4 gap-2">
                <FormControl fullWidth error={validationStatus === "error"}>
                  <TextField
                    label="New Status"
                    value={newStatus}
                    onChange={handleStatusChange}
                    error={validationStatus === "error"}
                    helperText={validationMessage}
                  />
                </FormControl>
                <Button
                  onClick={handleAddStatus}
                  color="primary"
                  variant="contained"
                  className="!mt-2"
                >
                  Add
                </Button>
              </Box>
            ) : (
              <Button
                onClick={handleAddStatusClick}
                variant="outlined"
                color="secondary"
              >
                Add new status
              </Button>
            )}

            <Box className="mt-5">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSaveStatus}
              >
                Save Status List
              </Button>
            </Box>
          </Box>
          {/* Invite members */}
          <Box className="card !rounded-lg text-black dark:text-white my-5 lg:w-1/2 pt-5 px-7 pb-7">
            <Typography variant="h5" className="text-xl font-bold mb-4">
              Invite Member
            </Typography>
            <List>
              {orgInvites.map((orgInv, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteInvite(orgInv._id)}
                  >
                    <Close />
                  </IconButton>
                  }
                  style={{ border: "1px solid #ccc", borderRadius: "8px", marginBottom: "8px" }}
                >
                  <Box className="flex flex-col gap-2">
                  <ListItemText primary={"Email: " + orgInv.email} />
                  <ListItemText primary={"Role: " + orgInv.role} />
                  <ListItemText primary={"Status: " + (orgInv.accepted ? "Accepted" : "Pending")} />
                  </Box>
                </ListItem>
              ))}
            </List>

            {showInviteInput ? (
              <Box className="flex mb-4 gap-2">
                <FormControl fullWidth error={validationStatus === "error"}>
                  <TextField
                    label="Email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                    error={validationStatus === "error"}
                    helperText={validationMessage}
                    style={{ marginBottom: "8px" }}
                  />

                  {/* Role dropdown with options Member and Admin*/}
                  <TextField
                    select
                    label="Role"
                    value={inviteRole}
                    onChange={(e) => setInviteRole(e.target.value)}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="Member">Member</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                  </TextField>
                </FormControl>
              </Box>
            ) : (
              <Button
                onClick={() => setShowInviteInput(true)}
                variant="outlined"
                color="secondary"
              >
                Invite Member
              </Button>
            )}

            <Box className="mt-5">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleInviteSave}
              >
                Invite Member
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="p-7">No permission</Box>
      )}
    </>
  );
};

export default OrgManage;
