import React, { useState, useMemo, createContext, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { UserProvider } from "./hooks/useUser";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getDesignTokens from "./layout/CustomPalette";

// Import MUI CSS and other third-party libraries first
import "jsvectormap/dist/css/jsvectormap.css";
import "flatpickr/dist/flatpickr.min.css";

// Import Tailwind CSS and custom CSS after MUI and third-party styles
import "./css/style.css";
import { SnackbarProvider } from "./context/SnackbarContext";
import { CssBaseline } from "@mui/material";

const Root = () => {
  const ColorModeContext = createContext({ toggleColorMode: () => {} });
  const [mode, setMode] = useState<"light" | "dark">("light");
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [],
  );

  return (
    <UserProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <ColorModeContext.Provider value={colorMode}>
            <SnackbarProvider>
              <CssBaseline />
              <App />
            </SnackbarProvider>
          </ColorModeContext.Provider>
        </ThemeProvider>
      </Router>
    </UserProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);
