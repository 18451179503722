import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import About from "../../components/Authentication/About";
import { StatusType } from "../../types/statusType";
import { getResponseClassName, sendRequest } from "../../utils/utils";
import { useUser } from "../../hooks/useUser";
import { SIGNIN_API, SIGNUP_URL, TOKEN_NAME } from "../../utils/consts";
import { Box, Typography, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();

  const [responseStatus, setResponseStatus] = useState<StatusType>("default");
  const [responseMessage, setResponseMessage] = useState("");

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const [validationStatus, setValidationStatus] = useState({
    emailStatus: "default" as StatusType,
    passwordStatus: "default" as StatusType,
  });

  const [validationMessage, setValidationMessage] = useState({
    emailMessage: "",
    passwordMessage: "",
  });

  const validateInput = (name: string, value: string) => {
    switch (name) {
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return value
          ? emailRegex.test(value)
            ? ""
            : "Invalid email format"
          : "Email is required";
      case "password":
        return value ? "" : "Password is required";
      default:
        return "";
    }
  };

  const validateAndSetField = (name: string, value: string) => {
    const errorMessage = validateInput(name, value);
    const status = errorMessage ? "error" : "default";

    setCredentials((prev) => ({ ...prev, [name]: value }));
    setValidationStatus((prev) => ({ ...prev, [`${name}Status`]: status }));
    setValidationMessage((prev) => ({
      ...prev,
      [`${name}Message`]: errorMessage,
    }));

    return !errorMessage;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    validateAndSetField(name, value);
  };

  const validateForm = () => {
    const fieldsToValidate = [
      { name: "email", value: credentials.email },
      { name: "password", value: credentials.password },
    ];

    let isValid = true;

    fieldsToValidate.forEach((field) => {
      if (!validateAndSetField(field.name, field.value)) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const data = { ...credentials };
      const response = await sendRequest(SIGNIN_API, "POST", data);

      if (response.status === 200 && response.data && response.data.user) {
        setResponseStatus("success");
        setResponseMessage("User signed in successfully");
        setUser({
          id: response.data.user._id,
          organization: response.data.user.Organization,
          ...response.data.user,
        });
        localStorage.setItem(TOKEN_NAME, response.data.token);
        navigate("/");
      } else {
        setResponseStatus("error");
        setResponseMessage(response.message);
      }
    } catch (error) {
      setResponseStatus("error");
      setResponseMessage("Internal Server Error");
    }
  };

  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle between true and false
  };

  return (
    <>
      <Box className="flex justify-center">
        <Typography
          color="dark"
          sx={{
            fontSize: 24,
            fontWeight: 800,
            color: (theme) => theme.palette.secondary.dark,
          }}
        >
          See what you can do with FinQuantum Inc.
        </Typography>
      </Box>
      <Box className="flex flex-wrap">
        <About />

        <Box className="form-container xl:w-1/2 !w-[700px]">
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email Address"
              type="email"
              name="email"
              value={credentials.email}
              error={validationStatus.emailStatus === "error"}
              helperText={validationMessage.emailMessage}
              onChange={handleChange}
              onBlur={handleChange}
              fullWidth
              margin="normal"
              sx={{
                marginBlockEnd: 5,
              }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}  // Change type based on state
              name="password"
              value={credentials.password}
              error={validationStatus.passwordStatus === "error"}
              helperText={validationMessage.passwordMessage}
              onChange={handleChange}
              onBlur={handleChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                // Add an adornment to the end of the input field for the toggle button
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box className={getResponseClassName(responseStatus)}>
              {responseMessage}
            </Box>

            <Box className="mt-15 mb-5 flex flex-col gap-6">
              <Box className="flex justify-center">
                <Button
                  className="login-btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Log In
                </Button>
              </Box>
              <Box className="flex justify-center">
                <Typography>
                  Don't have any account?{" "}
                  <Link to={SIGNUP_URL} className="text-primary">
                    Sign Up
                  </Link>
                </Typography>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default SignIn;
