import React from "react";
import { Product } from "../../types/product";
import ReportInfoForm from "../../components/Report/ReportInfoForm";
import { Breadcrumbs, Typography } from "@mui/material";

const AddReport: React.FC<{ product: Product }> = ({ product }) => {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" className="breadcrumb">
          Generate Report
        </Typography>
      </Breadcrumbs>
      <ReportInfoForm product={product} />
    </>
  );
};

export default AddReport;
